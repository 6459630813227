import React from 'react'
import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
// import Stores from '../components/stores'

// import Leaderboard from '../components/leaderboard'
// import basesBadge from '../images/challenge/badges/bases.png'
// import kitBadge from '../images/challenge/badges/kit.png'
// import macBadge from '../images/challenge/badges/mac.png'
// import macLowResBadge from '../images/challenge/badges/mac-low-res.png'
import winBadge86 from '../images/challenge/badges/win-86.png'
import winBadge64 from '../images/challenge/badges/win-64.png'
import winBadge32 from '../images/challenge/badges/win-32.png'
import appStoreBadge from '../images/challenge/badges/app-store.png'
import playStoreBadge from '../images/challenge/badges/play-store.png'
import huaweiBadge from '../images/challenge/badges/huawei-store.png'
import logoReto2021 from '../images/torneo2021/2021_VikidzLogo.png'
import styles from './torneo2021.module.css'

import schoolLogo from "../images/logo.png"
import tahlLogo from "../images/logo-train-at-home.png"

// const badgeGroupClasses = 'w-1/2 sm:w-1/4'
// const badgeClasses = 'mt-3 block w-3/5'

const DownloadsPage = ({ data }) => {
  const { formatMessage } = useIntl()

  return (
    <Layout isRetoPage={false} headerLinkColor="lg:text-purple">
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.downloads.title' })}
        description={formatMessage({ id: 'pages.downloads.description' })}
      />

      {/* <section className="pt-48 pb-45 bg-blue-lightest">
        <div className="custom-container custom-container--large">
          <div className="flex justify-between">
            <div className="w-2/5">
              <Img
                alt="Logo"
                className="w-3/5 mx-auto mb-16"
                fluid={data.vikidz.childImageSharp.fluid}
              />

              <Stores
                app="vikidz"
                appStoreClasses="w-32 mx-auto"
                playStoreClasses="w-32 mx-auto mt-4"
              />
            </div>

            <div className="w-2/5">
              <Img
                alt="Train At Home Logo"
                className="w-3/5 mx-auto mb-12"
                fluid={data.trainAtHome.childImageSharp.fluid}
              />

              <Stores
                app="trainAtHome"
                appStoreClasses="w-32 mx-auto"
                playStoreClasses="w-32 mx-auto mt-4"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* 
      
      **************************************
      ¬¬  Torneo de mate 2021 downloads   ¬¬
      **************************************
      
      */}

      <section className="pt-40 pb-45">
        <div className="custom-container text-purple-darker">
          <div className="my-5 flex flex-wrap custom-container">
            <div className="ml-10 p-10">
              {/* <a
              href="/torneo"> */}
              <img 
                src={schoolLogo} 
                alt="Torneo de Mate"
                className={styles.logo}/>
              {/* </a> */}
            </div>

            <div className="ml-10 ">
              <a
              href="https://apps.apple.com/mx/app/vikidz-school/id1435978730?l=en"
              target="_blank"
              rel="noreferrer noopener"
              >
                <img className="w-3/5 mx-auto mb-1" src={appStoreBadge} alt="App iOS" />
              </a>
              <a
              href="https://play.google.com/store/apps/details?id=io.vikidz.vikidz&hl=es_MX"
              target="_blank"
              rel="noreferrer noopener"
              >
                <img className="w-3/5 mx-auto mb-2" src={playStoreBadge} alt="App Android" />
              </a>
              <a
              href="https://appgallery.huawei.com/#/app/C103322535"
              target="_blank"
              rel="noreferrer noopener"
              >
                <img className="w-3/5 mx-auto mb-1" src={huaweiBadge} alt="App Android" />
              </a>
            </div>
          </div>
          <div className="my-5 flex flex-wrap custom-container">
            <div className="ml-10">
            <a
                href="/schoolBuilds-210910/Instalar_Vikidz_School_x64_210910.exe"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="w-3/4 mx-auto mb-3" src={winBadge64} alt="Instalador Windows x64" />
              </a>
              <a
                href="/schoolBuilds-210910/Instalar_Vikidz_School_LowRes.exe"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="w-3/6 mx-auto mb-5" src={winBadge32} alt="Instalador Windows x32" />
              </a>
            <a
                href="/schoolBuilds-210910/Instalar_Vikidz_School_x86_210910.exe"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="w-3/6 mx-auto mb-3" src={winBadge86} alt="Instalador Windows x86" />
              </a>
              {/* <a className="w-1/3 p-2 inline-block underline" 
                  href="https://www.facebook.com/vikidz.global/videos/248095770441158"
                  target="_blank"
                  rel="noreferrer noopener">
                ¡Ve nuestro video de instalación!
              </a>  */}

            </div>
            
            {/* Guías de instalación */}
            
            {/* <div className="my-5 flex flex-wrap custom-container">
               <a className="w-1/3 p-2 inline-block underline" href="/torneo/aviso-de-privacidad-torneo-vikidz.pdf" download>
                Aviso de privacidad
              </a> 
               <a className="w-1/3 p-2 inline-block underline" href="/torneo/guia-de-instalacion-mac.pdf" download>
                Guía de instalación<br />Mac
              </a>
              <a className="w-1/3 p-2 inline-block underline" href="/torneo/guia-de-instalacion-pc.pdf" download>
                Guía de instalación<br />Windows
              </a> 
            </div> */}
          </div>
        </div>
      </section>
  {/* 
      
      **************************************
      ¬¬  Train at home downloads   ¬¬
      **************************************
      
      */}
      <section className="">
        <div className="custom-container text-purple-darker">
          <div className="my-5 flex flex-wrap custom-container">
            <div className="ml-10 p-10">
              
              <img 
                src={tahlLogo} 
                alt="Torneo de Mate"
                className={styles.logo}/>
            </div>

            <div className="ml-10 ">
              <a
              href="https://apps.apple.com/mx/app/train-at-home/id1481478661?l=en"
              target="_blank"
              rel="noreferrer noopener"
              >
                <img className="w-3/5 mx-auto mb-1" src={appStoreBadge} alt="App iOS" />
              </a>
              <a
              href="https://play.google.com/store/apps/details?id=io.vikidz.trainathome&hl=es_MX"
              target="_blank"
              rel="noreferrer noopener"
              >
                <img className="w-3/5 mx-auto mb-2" src={playStoreBadge} alt="App Android" />
              </a>
              <a
              href="https://appgallery.huawei.com/#/app/C102366663"
              target="_blank"
              rel="noreferrer noopener"
              >
                <img className="w-3/5 mx-auto mb-1" src={huaweiBadge} alt="App Android" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    vikidz: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trainAtHome: file(relativePath: { eq: "logo-train-at-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default DownloadsPage
